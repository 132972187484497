@import url("https://fonts.googleapis.com/css2?family=Encode+Sans+SC:wght@200&family=Montserrat:wght@400&family=Tourney:wght@300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");

select.form-select:focus {
  outline: 1px solid #2c2e33;
}
.form-select:focus {
  border: 1px solid rgba(71, 164, 71, 0.5);
  background-color: #2a3038;
}

.form-select,
.form-control {
  border: 1px solid #2c2e33;
  /* height: calc(2.25rem + 2px); */
  font-weight: 400;
  font-size: 12px;
  padding: 0.375rem 0.6875rem;
  background-color: #2a3038;
  border-radius: 2px;
  color: #fff !important;
}

.form-select {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23f4faf0' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border-radius: 0.375rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.btn-close {
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em 0.25em;
  color: #000;
  background: transparent
    url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath fill='none' stroke='%23f4faf0' d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e")
    center/1em auto no-repeat;
  border: 0;
  border-radius: 0.375rem;
  opacity: 0.5;
}

.nav.nav-tabs .nav-link {
  color: #ebecf1;
}

.nav.nav-tabs .nav-link.active {
  color: #dcd2d3;
}

label,
th,
td,
button {
  color: #dcd2d3 !important;
  /* font-family: 'Poppins', sans-serif !important; */
  font-family: "Open Sans", sans-serif !important;
  /* font-family: 'Montserrat', sans-serif !important; */
  font-weight: 500 !important;
  font-size: 14px;
}

/* th, td {
    background-color: rgba(255, 255, 255, 0.5);
} */

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.08);
}
.table-striped tbody tr:nth-of-type(even) {
  background-color: rgba(255, 255, 255, 0.02);
}

.tab-content {
  border: 1px solid #2c2e33;
  padding: 2px;
}

.f-Poppins {
  font-family: "Poppins", sans-serif;
}

.f-Montserrat {
  font-family: "Montserrat", sans-serif;
}

.f-Tourney {
  font-family: "Tourney", sans-serif;
}

.f-OpenSans {
  font-family: "Open Sans", sans-serif;
}

.f-Encode {
  font-family: "Encode Sans SC", sans-serif;
}

.fs-14px {
  font-size: 14px !important;
}

.fw-100 {
  font-weight: 100;
}

.fw-200 {
  font-weight: 200;
}

.fw-300 {
  font-weight: 300;
}

.fw-400 {
  font-weight: 400;
}

.fw-500 {
  font-weight: 500;
}

.fw-600 {
  font-weight: 600;
}

.fw-700 {
  font-weight: 700;
}

.files-list-item-preview-image {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.files-list li.files-list-item {
  list-style-type: none;
}

.files-list-item-content {
  margin-left: 5px;
}

.files-list-item-preview {
  display: flex;
  margin-top: 5px;
  border-radius: 30px;
  border: 1px solid #dcd2d3;
  /* overflow: hidden; */
}
.modal {
  overflow: scroll !important;
}
.form-label {
  display: block;
}

ul.content_project_data {
  margin: 0;
  list-style: none;
}

ul.content_series_data {
  margin: 0;
  list-style: none;
}

ul.content_episodes_data {
  margin: 0;
  list-style: none;
}

ul.content_videos_data {
  margin: 0;
  list-style: none;
}

.center-flexbox {
  display: flex;
  align-items: center;
  justify-content: center;
}

.sidebar .sidebar-brand-wrapper .sidebar-brand img.app-logo {
  width: 40px;
  height: 40px;
}

.css-3dzjca-MuiPaper-root-MuiPopover-paper-MuiMenu-paper {
  background-color: #2a3038 !important;
}

.apexcharts-legend-text {
  color: white !important;
}
.menu-title,
.nav-link {
  color: white !important;
}
.apexcharts-toolbar {
  display: none !important;
}

.apexcharts-menu-item {
  color: black !important;
}
/* .apexcharts-text,
.apexcharts-xaxis-label {
  color: white !important;
} */
.css-r8u8y9 {
  background-color: #2a3038 !important;
  color: white !important;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.fa-toggle-on {
  color: rgb(107, 198, 223);
}
.color-green {
  color: green !important;
}

.optionContainer {
  background: #2a3038 !important;
}
.chip {
  background-color: #2c2e33;
}
