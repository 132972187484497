.container-fluid,
.container-lg,
.container-md,
.container-sm,
.container-xl {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 768px) {
  .row--grid {
    margin-left: -15px;
    margin-right: -15px;
  }
}
.main__title-link {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 120px;
  border-radius: 6px;
  border: 2px solid #f9ab00;
  margin-left: auto;
  font-size: 14px;
  color: #fff;
  text-transform: uppercase;
}

a {
  transition: 0.4s;
  transition-property: color, background-color, border-color, opacity;
}
a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
}
.stats {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  background-color: #222028;
  padding: 20px;
  height: 110px;
  width: 100%;
  margin-bottom: 20px;
  position: relative;
  border-radius: 6px;
}

.stats i {
  font-size: 42px;
  position: absolute;
  bottom: 5px;
  right: 20px;
  color: #f9ab00;
}
.dashbox {
  background-color: #222028;
  margin-bottom: 20px;
  border-radius: 6px;
  overflow: hidden;
}

.dashbox__title h3 i {
  font-size: 18px;
  margin-right: 10px;
  color: #ffc312;
  line-height: 26px;
}
*,
::after,
::before {
  box-sizing: inherit;
}
*,
::after,
::before {
  box-sizing: border-box;
}
user agent stylesheet i {
  font-style: italic;
}
.dashbox__title h3 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  color: #fff;
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  margin-bottom: 0;
}

.dashbox__title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  border-bottom: 1px solid #1a191f;
}
.dashbox__wrap {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  /* align-items: center; */
}
.dashbox__refresh {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 26px;
  height: 26px;
  color: #fff;
  font-size: 16px;
}
a {
  transition: 0.4s;
  transition-property: color, background-color, border-color, opacity;
}

.dashbox__more {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 26px;
  background-color: #1a191f;
  font-size: 12px;
  margin-left: 15px;
  color: #fff;
  border-radius: 6px;
}
a {
  transition: 0.4s;
  transition-property: color, background-color, border-color, opacity;
}
a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
}
.dashbox__table-wrap {
  position: relative;
  display: block;
  width: 100%;
  padding: 20px;
}
.dashbox__table-wrap {
  position: relative;
  display: block;
  width: 100%;
  padding: 20px;
}
.mCustomScrollbar {
  -ms-touch-action: pinch-zoom;
  touch-action: pinch-zoom;
}

.mCustomScrollBox {
  position: relative;
  overflow: hidden;
  height: 100%;
  max-width: 100%;
  outline: 0;
  direction: ltr;
}
*,
::after,
::before {
  box-sizing: inherit;
}
*,
::after,
::before {
  box-sizing: border-box;
}

.mCSB_horizontal.mCSB_outside > .mCSB_container {
  min-height: 100%;
}
.mCSB_container {
  overflow: hidden;
  width: auto;
  height: auto;
}

table {
  border-collapse: collapse;
}
.main__table--dash thead {
  border-bottom: 1px solid #1a191f;
}
.main__table thead {
  border-bottom: 20px solid #1a191f;
}
tr {
  display: table-row;
  vertical-align: inherit;
  unicode-bidi: isolate;
  border-color: inherit;
}
tbody {
  display: table-row-group;
  vertical-align: middle;
  unicode-bidi: isolate;
  border-color: inherit;
}

table {
  border-collapse: collapse;
}
user agent stylesheet table {
  border-collapse: separate;
  text-indent: initial;
  border-spacing: 2px;
}
.main__table--dash tbody tr {
  border-bottom: none;
  background-color: transparent;
}
.main__table tbody tr {
  border-bottom: 10px solid #1a191f;
}
.main__table--dash tbody td {
  padding: 0;
}
.main__table tbody td {
  padding: 0;
  background-color: transparent;
  border: 0px solid transparent;
}
.main__table tbody td:first-child .main__table-text {
  border-radius: 6px 0 0 6px;
}
.main__table--dash tbody td .main__table-text {
  background-color: transparent;
  /* padding: 10px 15px 0 0; */
}
.main__table-text {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  font-size: 16px;
  color: #fff;
  font-weight: 400;
  line-height: 30px;
  padding: 15px;
  background-color: #222028;
  white-space: nowrap;
}
@media (min-width: 1500px) {
  .mCS-custom-bar3.mCSB_outside + .mCSB_scrollTools {
    display: none !important;
  }
}
.mCS-custom-bar3.mCSB_outside + .mCSB_scrollTools {
  right: 20px;
  left: 20px;
  bottom: 10px;
  background-color: #1a191f;
  border-radius: 4px;
  overflow: hidden;
}
.fa-chart-simple:before {
  content: "";
}

.dashbox {
  background-color: #222028;
  margin-bottom: 20px;
  border-radius: 6px;
  overflow: hidden;
}
.dashbox__title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  border-bottom: 1px solid #1a191f;
}
.dashbox__title h3 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  color: #fff;
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  margin-bottom: 0;
}
.dashbox__title h3 i {
  font-size: 18px;
  margin-right: 10px;
  color: #ffc312;
  line-height: 26px;
}
.icons:before {
  content: "";
}
.dashbox__wrap {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.dashbox__refresh {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 26px;
  height: 26px;
  color: #fff;
  font-size: 16px;
}
a {
  transition: 0.4s;
  transition-property: color, background-color, border-color, opacity;
}
a {
  color: white;
  text-decoration: none;
  background-color: transparent;
}
.dashbox__table-wrap {
  position: relative;
  display: block;
  width: 100%;
  padding: 20px;
}
.mCustomScrollbar {
  -ms-touch-action: pinch-zoom;
  touch-action: pinch-zoom;
}
.mCS-custom-bar3 {
  opacity: 1;
}
.mCustomScrollBox {
  position: relative;
  overflow: hidden;
  height: 100%;
  max-width: 100%;
  outline: 0;
  direction: ltr;
}
.mCSB_horizontal.mCSB_outside > .mCSB_container {
  min-height: 100%;
}

.mCSB_container {
  overflow: hidden;
  width: auto;
}

.main__table {
  width: 100%;
  min-width: 150px;
  border-spacing: 0;
}
table {
  border-collapse: collapse;
}
.main__table--dash thead {
  border-bottom: 1px solid #1a191f;
}

.main__table thead {
  border-bottom: 20px solid #1a191f;
}
tr {
  display: table-row;
  vertical-align: inherit;
  unicode-bidi: isolate;
  border-color: inherit;
}

.main__table thead th {
  font-size: 12px;
  color: rgba(255, 255, 255, 0.7);
  font-weight: 400;
  padding: 0 15px 0 15px;
  line-height: 100%;
  margin-bottom: 0;
  border: none;
}
th {
  text-align: inherit;
}
tbody {
  display: table-row-group;
  vertical-align: middle;
  unicode-bidi: isolate;
  border-color: inherit;
}
.main__table--dash tbody tr {
  border-bottom: none;
  background-color: transparent;
}
.main__table tbody tr {
  border-bottom: 10px solid #1a191f;
}
.main__table--dash tbody td {
  padding: 0;
}
.main__table tbody td {
  padding: 0;
  background-color: transparent;
  border: 0px solid transparent;
}
.main__table tbody td:first-child .main__table-text {
  border-radius: 6px 0 0 6px;
}
.main__table--dash tbody td .main__table-text {
  background-color: transparent;
  padding: 10px 15px 0 0;
}
.main__table-text {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  font-size: 16px;
  color: #fff;
  font-weight: 400;
  line-height: 30px;
  padding: 15px;
  background-color: #222028;
  white-space: nowrap;
}
@media (min-width: 1500px) {
  .mCS-custom-bar3.mCSB_outside + .mCSB_scrollTools {
    display: none !important;
  }
}
.mCS-custom-bar3.mCSB_outside + .mCSB_scrollTools {
  right: 20px;
  left: 20px;
  bottom: 10px;
  background-color: #1a191f;
  border-radius: 4px;
  overflow: hidden;
}
.mCustomScrollBox
  + .mCSB_scrollTools
  + .mCSB_scrollTools.mCSB_scrollTools_horizontal,
.mCustomScrollBox + .mCSB_scrollTools.mCSB_scrollTools_horizontal {
  bottom: -26px;
}
.mCS-custom-bar3.mCSB_scrollTools_horizontal {
  height: 3px;
}
.mCSB_scrollTools.mCSB_scrollTools_horizontal {
  width: auto;
  height: 16px;
  top: auto;
  right: 0;
  bottom: 0;
  left: 0;
}
.mCSB_outside + .mCSB_scrollTools {
  right: -26px;
}
.mCS-custom-bar3 {
  opacity: 1;
}
.mCSB_scrollTools .mCSB_draggerContainer {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: auto;
}
.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_dragger {
  width: 30px;
  height: 100%;
  left: 0;
}
.mCSB_scrollTools .mCSB_dragger {
  cursor: pointer;
  width: 100%;
  height: 30px;
  z-index: 1;
}
.mCS-custom-bar3.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  background-color: #f9ab00;
  height: 3px;
  border-radius: 4px;
  margin: 0;
}
.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  width: 100%;
  height: 4px;
  margin: 6px auto;
}
.mCS-custom-bar3.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  height: 3px;
  background-color: transparent;
  border-radius: 4px;
  margin: 0;
}

.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  width: 100%;
  height: 2px;
  margin: 7px 0;
}
